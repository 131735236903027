import React, { useState, useEffect } from 'react';
import { Modal, Button, Table, Pagination } from 'react-bootstrap';
import { IoArrowBackCircle } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { MdDelete } from "react-icons/md";
import Header from '../Header/header';
import { FaEdit } from 'react-icons/fa';

const EventCalendar = () => {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState({ name: '', description: '', venue: '', schedule: '', date: '' });
    const [events, setEvents] = useState([]);
    const [editingId, setEditingId] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    useEffect(() => {
        fetchEvents();
    }, []);

    const fetchEvents = async () => {
        try {
            const response = await fetch('https://isameapi.emedha.in/pages/api/api/events');
            const data = await response.json();

            console.log(data)
            if (Array.isArray(data)) {
                const groupedEvents = data.reduce((acc, event) => {
                    const eventDate = new Date(event.date).toLocaleDateString();
                    if (!acc[eventDate]) acc[eventDate] = [];
                    acc[eventDate].push(event);
                    return acc;
                }, {});

                const sortedDates = Object.keys(groupedEvents).sort((a, b) => new Date(a) - new Date(b));

                const sortedEvents = {};
                sortedDates.forEach(date => {
                    sortedEvents[date] = groupedEvents[date];
                });

                setEvents(sortedEvents);

            } else {
                console.error('Expected an array but received:', data);
                setEvents({});
            }
        } catch (error) {
            console.error('Error fetching events:', error);
            setEvents({});
        }
    };

    const handleShow = (event = null) => {
        if (event) {
            setFormData({
                name: event.name,
                description: event.description,
                venue: event.venue,
                schedule: event.schedule,
                date: event.date,
            });
            setEditingId(event.id);
        } else {
            setFormData({ name: '', venue: '', schedule: '', date: '' });
            setEditingId(null);
        }
        setShowModal(true);
    };


    const handleClose = () => setShowModal(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        const method = editingId ? 'PUT' : 'POST';
        const url = editingId
            ? `https://isameapi.emedha.in/pages/api/api/events/${editingId}`
            : 'https://isameapi.emedha.in/pages/api/api/events';

        const eventData = {};
        if (formData.name) eventData.name = formData.name;
        if (formData.description) eventData.description = formData.description;
        if (formData.venue) eventData.venue = formData.venue;
        if (formData.schedule) eventData.schedule = formData.schedule;
        if (formData.date) eventData.date = formData.date;

        try {
            const response = await fetch(url, {
                method,
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(eventData),
            });

            if (response.ok) {
                fetchEvents();
                handleClose();
            } else {
                console.error('Error submitting event:', response.statusText);
            }
        } catch (error) {
            console.error('Error submitting event:', error);
        }
    };


    const handleDelete = async (eventId) => {
        if (window.confirm('Are you sure you want to delete this event?')) {
            const response = await fetch(`https://isameapi.emedha.in/pages/api/api/events/${eventId}`, {
                method: 'DELETE',
            });
            if (response.ok) {
                fetchEvents(); // Refresh events list
            } else {
                console.error('Error deleting event:', response.statusText);
            }
        }
    };

    const handleBack = () => {
        navigate(-1);
    };


    // Pagination logic for dates (not events directly)
    const totalPages = Math.ceil(Object.keys(events).length / itemsPerPage);
    const currentDates = Object.keys(events).slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    return (
        <>
            <Header />
            <div className="container mt-5">
                <h1 className="d-flex justify-content-between align-items-center mb-4">
                    <IoArrowBackCircle onClick={handleBack} style={{ cursor: 'pointer' }} />
                    Event Calendar
                    <Button className="btn btn-primary btn-sm" onClick={() => handleShow()}>+ Add</Button>
                </h1>

                <Table striped bordered hover responsive="sm">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Schedule</th>
                            <th>Event Particulars</th>
                            <th>Description</th>
                            <th>Proposed Venue / Remarks</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentDates.map((date, index) => (
                            <React.Fragment key={date}>
                                <tr>
                                    <td colSpan="7" style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                        {new Date(date).toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
                                    </td>
                                </tr>      
                                {Array.isArray(events[date]) && events[date].map((event, eventIndex) => (
                                    <tr key={event.id}>
                                        <td>{(currentPage - 1) * itemsPerPage + index + eventIndex + 1}</td>
                                        <td>{event.schedule}</td>
                                        <td>{event.name}</td>
                                        <td>{event.description || ''}</td>
                                        <td>{event.venue || ''}</td>
                                        <td>
                                            <FaEdit className='me-3' style={{ cursor: 'pointer' }} onClick={() => handleShow(event)} />
                                            <MdDelete style={{ cursor: 'pointer' }} onClick={() => handleDelete(event.id)} />
                                        </td>
                                    </tr>
                                ))}
                            </React.Fragment>
                        ))}
                    </tbody>
                </Table>

                {/* Modal for Adding/Editing Events */}
                <Modal show={showModal} onHide={handleClose} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>{editingId ? 'Edit Event' : 'Add Event'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="schedule" className="form-label">Schedule Time</label>
                                <input type="text" className="form-control" id="schedule" name="schedule" value={formData.schedule} onChange={handleInputChange} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label">Event Particulars</label>
                                <input type="text" className="form-control" id="name" name="name" value={formData.name} onChange={handleInputChange} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="description" className="form-label">Description</label>
                                <input type="text" className="form-control" id="description" name="description" value={formData.description} onChange={handleInputChange} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="venue" className="form-label">Proposed Venue / Remarks</label>
                                <input type="text" className="form-control" id="venue" name="venue" value={formData.venue} onChange={handleInputChange} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="date" className="form-label">Date</label>
                                <input type="text" className="form-control" id="date" name="date" value={formData.date} onChange={handleInputChange} />
                            </div>


                            <Button className="btn btn-primary btn-sm" type="submit">
                                Submit
                            </Button>
                        </form>
                    </Modal.Body>
                </Modal>

                {/* Pagination Controls */}
                <Pagination className="justify-content-center mt-4">
                    <Pagination.Prev onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} disabled={currentPage === 1} />
                    {[...Array(totalPages).keys()].map(page => (
                        <Pagination.Item key={page + 1} active={page + 1 === currentPage} onClick={() => setCurrentPage(page + 1)}>
                            {page + 1}
                        </Pagination.Item>
                    ))}
                    <Pagination.Next onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages} />
                </Pagination>
            </div>
        </>
    );
};

export default EventCalendar;




// import React, { useState, useEffect } from 'react';
// import { Modal, Button, Table, Pagination } from 'react-bootstrap';
// import { IoArrowBackCircle } from "react-icons/io5";
// import { useNavigate } from 'react-router-dom';
// import { MdDelete } from "react-icons/md";
// import { CiSearch } from 'react-icons/ci'; // Import CiSearch icon
// import Header from '../Header/header';
// import { FaEdit } from 'react-icons/fa';

// const EventCalendar = () => {
//     const navigate = useNavigate();
//     const [showModal, setShowModal] = useState(false);
//     const [formData, setFormData] = useState({ name: '', description: '', venue: '', schedule: '', date: '' });
//     const [events, setEvents] = useState([]);
//     const [editingId, setEditingId] = useState(null);
//     const [currentPage, setCurrentPage] = useState(1);
//     const [searchQuery, setSearchQuery] = useState(''); // Define searchQuery state
//     const itemsPerPage = 10;

//     useEffect(() => {
//         fetchEvents();
//     }, []);

//     const fetchEvents = async () => {
//         try {
//             const response = await fetch('https://isameapi.emedha.in/pages/api/api/events');
//             const data = await response.json();

//             console.log(data)
//             if (Array.isArray(data)) {
//                 const groupedEvents = data.reduce((acc, event) => {
//                     const eventDate = new Date(event.date).toLocaleDateString();
//                     if (!acc[eventDate]) acc[eventDate] = [];
//                     acc[eventDate].push(event);
//                     return acc;
//                 }, {});

//                 const sortedDates = Object.keys(groupedEvents).sort((a, b) => new Date(a) - new Date(b));

//                 const sortedEvents = {};
//                 sortedDates.forEach(date => {
//                     sortedEvents[date] = groupedEvents[date];
//                 });

//                 setEvents(sortedEvents);

//             } else {
//                 console.error('Expected an array but received:', data);
//                 setEvents({});
//             }
//         } catch (error) {
//             console.error('Error fetching events:', error);
//             setEvents({});
//         }
//     };

//     const handleShow = (event = null) => {
//         if (event) {
//             setFormData({
//                 name: event.name,
//                 description: event.description,
//                 venue: event.venue,
//                 schedule: event.schedule,
//                 date: event.date,
//             });
//             setEditingId(event.id);
//         } else {
//             setFormData({ name: '', venue: '', schedule: '', date: '' });
//             setEditingId(null);
//         }
//         setShowModal(true);
//     };

//     const handleClose = () => setShowModal(false);

//     const handleInputChange = (e) => {
//         const { name, value } = e.target;
//         setFormData({ ...formData, [name]: value });
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         const method = editingId ? 'PUT' : 'POST';
//         const url = editingId
//             ? `https://isameapi.emedha.in/pages/api/api/events/${editingId}`
//             : 'https://isameapi.emedha.in/pages/api/api/events';

//         const eventData = {};
//         if (formData.name) eventData.name = formData.name;
//         if (formData.description) eventData.description = formData.description;
//         if (formData.venue) eventData.venue = formData.venue;
//         if (formData.schedule) eventData.schedule = formData.schedule;
//         if (formData.date) eventData.date = formData.date;

//         try {
//             const response = await fetch(url, {
//                 method,
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify(eventData),
//             });

//             if (response.ok) {
//                 fetchEvents();
//                 handleClose();
//             } else {
//                 console.error('Error submitting event:', response.statusText);
//             }
//         } catch (error) {
//             console.error('Error submitting event:', error);
//         }
//     };

//     const handleDelete = async (eventId) => {
//         if (window.confirm('Are you sure you want to delete this event?')) {
//             const response = await fetch(`https://isameapi.emedha.in/pages/api/api/events/${eventId}`, {
//                 method: 'DELETE',
//             });
//             if (response.ok) {
//                 fetchEvents(); // Refresh events list
//             } else {
//                 console.error('Error deleting event:', response.statusText);
//             }
//         }
//     };

//     const handleBack = () => {
//         navigate(-1);
//     };

//     // Filter events based on search query
//     const filteredEvents = Object.keys(events).flatMap(date => {
//         return events[date].filter(event => {
//             // Ensure you're searching in the correct attributes
//             return (
//                 event.schedule?.toLowerCase().includes(searchQuery.toLowerCase()) ||
//                 event.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
//                 event.description?.toLowerCase().includes(searchQuery.toLowerCase()) ||
//                 event.venue?.toLowerCase().includes(searchQuery.toLowerCase())
//             );
//         });
//     });

//     // Pagination logic for filtered events (if you're paginating based on filtered data)
//     const totalPages = Math.ceil(filteredEvents.length / itemsPerPage);
//     const currentEvents = filteredEvents.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

//     return (
//         <>
//             <Header />
//             <div className="container mt-5">
//                 <div className="d-flex justify-content-between align-items-center mb-4">
//                     <IoArrowBackCircle onClick={handleBack} style={{ cursor: 'pointer', fontSize: '45px' }} />
//                     <h1> Event Calendar</h1>

//                     <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '3px', height: '40px', border: '1px solid black', paddingLeft: '10px', width: '380px' }}>
//                         <input
//                             style={{ border: 'none', paddingLeft: '10px', outline: 'none', width: '380px' }}
//                             type="search"
//                             aria-label="Search"
//                             value={searchQuery}
//                             onChange={(e) => setSearchQuery(e.target.value)} // Use setSearchQuery here
//                         />
//                         <CiSearch style={{ marginRight: '8px' }} />
//                     </div>
//                     <Button className="btn btn-primary btn-sm" onClick={() => handleShow()}>+ Add</Button>
//                 </div>

//                 <div className="table-responsive">
//                     <table className="table table-bordered table-striped">
//                         <thead>
//                             <tr>
//                                 <th>#</th>
//                                 <th>Schedule</th>
//                                 <th>Event Particulars</th>
//                                 <th>Description</th>
//                                 <th>Proposed Venue / Remarks</th>
//                                 <th>Actions</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {filteredEvents.length > 0 ? filteredEvents.map((event, index) => (
//                                 <tr key={event.id}>
//                                     <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
//                                     <td>{event.schedule}</td>
//                                     <td>{event.name}</td>
//                                     <td>{event.description || ''}</td>
//                                     <td>{event.venue || ''}</td>
//                                     <td>
//                                         <FaEdit className='me-3' style={{ cursor: 'pointer' }} onClick={() => handleShow(event)} />
//                                         <MdDelete style={{ cursor: 'pointer' }} onClick={() => handleDelete(event.id)} />
//                                     </td>
//                                 </tr>
//                             )) : (
//                                 <tr>
//                                     <td colSpan="6" className="text-center">No events found matching your search.</td>
//                                 </tr>
//                             )}
//                         </tbody>
//                     </table>
//                 </div>

//                 {/* Modal for Adding/Editing Events */}
//                 <Modal show={showModal} onHide={handleClose} size="lg">
//                     <Modal.Header closeButton>
//                         <Modal.Title>{editingId ? 'Edit Event' : 'Add Event'}</Modal.Title>
//                     </Modal.Header>
//                     <Modal.Body>
//                         <form onSubmit={handleSubmit}>
//                             <div className="mb-3">
//                                 <label htmlFor="schedule" className="form-label">Schedule Time</label>
//                                 <input type="text" className="form-control" id="schedule" name="schedule" value={formData.schedule} onChange={handleInputChange} />
//                             </div>
//                             <div className="mb-3">
//                                 <label htmlFor="name" className="form-label">Event Particulars</label>
//                                 <input type="text" className="form-control" id="name" name="name" value={formData.name} onChange={handleInputChange} />
//                             </div>
//                             <div className="mb-3">
//                                 <label htmlFor="description" className="form-label">Description</label>
//                                 <input type="text" className="form-control" id="description" name="description" value={formData.description} onChange={handleInputChange} />
//                             </div>
//                             <div className="mb-3">
//                                 <label htmlFor="venue" className="form-label">Proposed Venue / Remarks</label>
//                                 <input type="text" className="form-control" id="venue" name="venue" value={formData.venue} onChange={handleInputChange} />
//                             </div>
//                             <div className="mb-3">
//                                 <label htmlFor="date" className="form-label">Date</label>
//                                 <input type="text" className="form-control" id="date" name="date" value={formData.date} onChange={handleInputChange} />
//                             </div>

//                             <Button className="btn btn-primary btn-sm" type="submit">
//                                 Submit
//                             </Button>
//                         </form>
//                     </Modal.Body>
//                 </Modal>

//                 {/* Pagination Controls */}
//                 <Pagination className="justify-content-center mt-4">
//                     <Pagination.Prev onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} disabled={currentPage === 1} />
//                     {[...Array(totalPages).keys()].map(page => (
//                         <Pagination.Item key={page + 1} active={page + 1 === currentPage} onClick={() => setCurrentPage(page + 1)}>
//                             {page + 1}
//                         </Pagination.Item>
//                     ))}
//                     <Pagination.Next onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages} />
//                 </Pagination>
//             </div>
//         </>
//     );
// };


// export default EventCalendar;
