import React, { useState, useEffect } from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import axios from 'axios';

const SpeakerQRCode = () => {
  const [speakers, setSpeakers] = useState([]);
  
  useEffect(() => {
    const fetchSpeakers = async () => {
      try {
        const response = await axios.get('https://isameapi.emedha.in/pages/api/api/speakers');
        setSpeakers(response.data);
      } catch (error) {
        console.error('Error fetching speaker data:', error);
      }
    };
    fetchSpeakers();
  }, []);

  const isEventExpired = (eventDateString) => {
    const [day, month, year] = eventDateString.split('-');
    const eventDate = new Date(`${month}-${day}-${year}`);
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    return eventDate < currentDate;
  };

  return (
    <div className="container my-4">
      <div className="speakers-qr-container">
        <h3 className="text-center mb-4">SPEAKER QR CODES</h3>
        <div className="table-responsive">
          <table className="table table-bordered table-striped">
            <thead className="table-primary">
              <tr>
                <th>Name</th>
                <th>Title</th>
                <th>Image</th>
                <th>QR Code</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {speakers.map((speaker) => {
                const titleParts = speaker.title.split('\r\n');
                const eventDateString = titleParts.find(part => /\d{1,2}-\d{1,2}-\d{4}/.test(part));            
                const speakerData = {
                  name: speaker.name,
                  title: speaker.title,
                  imageUrl: speaker.imageUrl,
                  eventDate: eventDateString,
                };

                return (
                  <tr key={speaker.id} >
                    <td>{speaker.name}</td>
                    <td>{speaker.title}</td>
                    <td>
                      <img src={speaker.imageUrl} alt={speaker.name} width="100" height="100" />
                    </td>
                    <td>
                      <QRCodeCanvas 
                        value={JSON.stringify(speakerData)} 
                        size={128} 
                      />
                    </td>                 
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SpeakerQRCode;
