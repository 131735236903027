import React, { useEffect, useState } from 'react';
import { Modal, Button, Pagination } from 'react-bootstrap';
import { IoArrowBackCircle } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Header from '../Header/header';
import { MdDelete } from 'react-icons/md';
import { FaEdit } from 'react-icons/fa';
import { QRCodeCanvas } from 'qrcode.react';

const Speakers = () => {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState({ name: '', title: '', image: null });
    const [speakers, setSpeakers] = useState([]);
    const [editIndex, setEditIndex] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    useEffect(() => {
        const fetchSpeakers = async () => {
            try {
                const response = await axios.get('https://isameapi.emedha.in/pages/api/api/speakers');
                setSpeakers(response.data);
            } catch (error) {
                console.error('Error fetching speakers:', error);
            }
        };
        fetchSpeakers();
    }, []);

    const handleBack = () => {
        navigate(-1);
    };

    const handleShow = (index = null) => {
        if (index !== null) {
            setFormData({ ...speakers[index], image: null });
            setEditIndex(index);
        } else {
            setFormData({ name: '', title: '', image: null });
            setEditIndex(null);
        }
        setShowModal(true);
    };

    const handleClose = () => setShowModal(false);

    const handleInputChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'image') {
            setFormData({ ...formData, image: files[0] });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const { name, title, image } = formData;
        const dataToSubmit = { name, title, imageUrl: speakers[editIndex]?.imageUrl };
        const formDataToSend = new FormData();
        formDataToSend.append('name', name);
        formDataToSend.append('title', title);
        if (image) {
            formDataToSend.append('image', image);
        }
        try {
            if (editIndex !== null) {
                await axios.put(`https://isameapi.emedha.in/pages/api/api/speakers/${speakers[editIndex].id}`, formDataToSend);
            } else {
                const response = await axios.post('https://isameapi.emedha.in/pages/api/api/speakers', formDataToSend);
                setSpeakers([...speakers, response.data]);
            }
            setFormData({ name: '', title: '', image: '' });
            handleClose();
        } catch (error) {
            console.error('Error saving speaker:', error);
        }
    };

    const handleDelete = async (index) => {
        const speakerToDelete = speakers[index];
        if (window.confirm(`Are you sure you want to delete ${speakerToDelete.name}?`)) {
            try {
                await axios.delete(`https://isameapi.emedha.in/pages/api/api/speakers/${speakerToDelete.id}`);
                const newSpeakers = speakers.filter((_, i) => i !== index);
                setSpeakers(newSpeakers);
            } catch (error) {
                console.error('Error deleting speaker:', error);
            }
        }
    };

    // const isEventExpired = (eventDateString) => {
    //     const [day, month, year] = eventDateString.split('-');
    //     const eventDate = new Date(`${month}-${day}-${year}`);
    //     const currentDate = new Date();
    //     currentDate.setHours(0, 0, 0, 0);
    //     return eventDate < currentDate;
    // };

    // Pagination Logic
    const totalPages = Math.ceil(speakers.length / itemsPerPage);
    const currentSpeakers = speakers.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    return (
        <>
            <Header />
            <div className="container mt-5">
                <h1 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                    <IoArrowBackCircle onClick={handleBack} style={{ cursor: 'pointer' }} />
                    SPEAKERS & SESSIONS
                    <Button className="btn btn-primary btn-sm" onClick={() => handleShow()}>+ Add</Button>
                </h1>

                <div className="table-responsive">
                    <table className="table table-bordered table-striped">
                        <thead className="table-primary">
                            <tr>
                                <th>#</th>
                                <th>Name Of The Speaker</th>
                                <th>Title</th>
                                <th>Image</th>
                                <th>QR Code</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentSpeakers.map((speaker, index) => {
                                const titleParts = speaker.title.split('\r\n');
                                const eventDateString = titleParts.find(part => /\d{1,2}-\d{1,2}-\d{4}/.test(part));
                                // const expired = eventDateString ? isEventExpired(eventDateString) : false;
                                const speakerData = { name: speaker.name, title: speaker.title, imageUrl: speaker.imageUrl, eventDate: eventDateString, };

                                return (
                                    <tr key={speaker.id}>
                                        <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                        <td>{speaker.name}</td>
                                        <td>{speaker.title}</td>
                                        <td><img src={speaker.imageUrl} alt={speaker.name} style={{ width: '100px' }} /></td>
                                        <td><QRCodeCanvas value={JSON.stringify(speakerData)} size={128} /></td>
                                        <td>
                                            <FaEdit className='me-3' style={{ cursor: 'pointer' }} onClick={() => handleShow((currentPage - 1) * itemsPerPage + index)} />
                                            <MdDelete style={{ cursor: 'pointer' }} onClick={() => handleDelete((currentPage - 1) * itemsPerPage + index)} />
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>

                <Modal show={showModal} onHide={handleClose} size="lg">
                    <Modal.Header closeButton><Modal.Title>{editIndex !== null ? 'EDIT SPEAKER' : 'ADD SPEAKER'}</Modal.Title></Modal.Header>
                    <Modal.Body>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label">Name Of The Speaker</label>
                                <input type="text" className="form-control" id="name" name="name" value={formData.name} onChange={handleInputChange} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="title" className="form-label">Title</label>
                                <textarea className="form-control" id="title" name="title" value={formData.title} onChange={handleInputChange} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="image" className="form-label">Upload Image</label>
                                <input type="file" className="form-control" id="image" name="image" accept="image/*" onChange={handleInputChange} />
                            </div>
                            <button className="btn btn-primary btn-sm" type="submit">Submit</button>
                        </form>
                    </Modal.Body>
                </Modal>

                <Pagination className="justify-content-center mt-4">
                    <Pagination.Prev onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} disabled={currentPage === 1} />
                    {[...Array(totalPages).keys()].map(page => (
                        <Pagination.Item key={page + 1} active={page + 1 === currentPage} onClick={() => setCurrentPage(page + 1)}>
                            {page + 1}
                        </Pagination.Item>
                    ))}
                    <Pagination.Next onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages} />
                </Pagination>
            </div>
        </>
    );
};

export default Speakers;
