import React, { useState, useEffect } from 'react';
import Header from '../Header/header';
import { useNavigate } from 'react-router-dom';
import { IoArrowBackCircle } from 'react-icons/io5';
import { Button, Modal, Form, Table, Pagination } from 'react-bootstrap';
import axios from 'axios';

const Entertainment = () => {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState({
        schedule: '',
        date: '',
        title: '',
        description: '',
        image1: null,
        image2: null,
    });
    const [entries, setEntries] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);

    useEffect(() => {
        axios.get('https://isameapi.emedha.in/pages/api/entertainment')
            .then(response => {
                setEntries(response.data);
            })
            .catch(error => {
                console.error('There was an error fetching the entertainment data!', error);
            });
    }, []);

    const handleBack = () => {
        navigate(-1);
    };

    const handleShowModal = () => {
        setShowModal(true);
        setFormData({
            schedule: '',
            date: '',
            title: '',
            description: '',
            image1: null,
            image2: null,
        });
    };

    const handleCloseModal = () => setShowModal(false);

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'date') {
            setFormData({
                ...formData,
                [name]: value,
            });
        } else if (files) {
            setFormData({
                ...formData,
                [name]: files[0],
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleAdd = async (e) => {
        e.preventDefault();

        const data = new FormData();
        data.append('schedule', formData.schedule);
        data.append('date', formData.date);
        data.append('title', formData.title);
        data.append('description', formData.description);
        data.append('image1', formData.image1);
        data.append('image2', formData.image2);

        try {
            await axios.post('https://isameapi.emedha.in/pages/api/entertainment', data);
            const response = await axios.get('https://isameapi.emedha.in/pages/api/entertainment');
            setEntries(response.data);

            setShowModal(false);
        } catch (error) {
            console.error('There was an error submitting the data!', error);
        }
    };

    const handleEdit = async (e) => {
        e.preventDefault();

        const data = new FormData();
        data.append('schedule', formData.schedule);
        data.append('date', formData.date);
        data.append('title', formData.title);
        data.append('description', formData.description);
        data.append('image1', formData.image1);
        data.append('image2', formData.image2);

        try {
            await axios.put(`https://isameapi.emedha.in/pages/api/entertainment/${formData.id}`, data);
            const response = await axios.get('https://isameapi.emedha.in/pages/api/entertainment');
            setEntries(response.data);
            setShowModal(false);
        } catch (error) {
            console.error('There was an error updating the data!', error);
        }
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`https://isameapi.emedha.in/pages/api/entertainment/${id}`);
            const response = await axios.get('https://isameapi.emedha.in/pages/api/entertainment');
            setEntries(response.data);
        } catch (error) {
            console.error('There was an error deleting the entry!', error);
        }
    };

    // const handleOpenEdit = (entry) => {
    //     const date = new Date(entry.date);
    //     const formattedDate = date.toISOString().split('T')[0];  
    //     setFormData({
    //         ...entry,
    //         date: formattedDate,  
    //     });
    
    //     setShowModal(true);
    // };
    
    const handleOpenEdit = (entry) => {
        // Check if entry.date is a valid date
        const date = new Date(entry.date);
        const formattedDate = date instanceof Date && !isNaN(date) ? date.toISOString().split('T')[0] : '';  
    
        setFormData({
            ...entry,
            date: formattedDate,  
        });
    
        setShowModal(true);
    };
    


    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentEntries = entries.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(entries.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <>
            <Header />
            <div className="container mt-5">
                <h1 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                    <IoArrowBackCircle onClick={handleBack} style={{ cursor: 'pointer' }} />
                    Entertainment
                    <Button className="btn btn-primary btn-sm" onClick={handleShowModal}>+ Add</Button>
                </h1>

                {/* Modal for Add or Edit Entertainment */}
                <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{formData.title ? 'Edit Schedule' : 'Add Schedule'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={formData.id ? handleEdit : handleAdd}>
                            <Form.Group controlId="schedule">
                                <Form.Label>Schedule</Form.Label>
                                <Form.Control type="text" placeholder="Enter schedule" name="schedule" value={formData.schedule} onChange={handleChange} />
                            </Form.Group>

                            <Form.Group controlId="date">
                                <Form.Label>Date</Form.Label>
                                {/* <Form.Control id='date' type="date" name="date" value={formData.date} onChange={handleChange} /> */}
                                <Form.Control type="text" placeholder="date" name="date" value={formData.date} onChange={handleChange} />
                            </Form.Group>

                            <Form.Group controlId="title">
                                <Form.Label>Title</Form.Label>
                                <Form.Control type="text" placeholder="Enter title" name="title" value={formData.title} onChange={handleChange} />
                            </Form.Group>

                            <Form.Group controlId="description">
                                <Form.Label>Description</Form.Label>
                                <Form.Control type="text" placeholder="Enter description" name="description" value={formData.description} onChange={handleChange} />
                            </Form.Group>

                            <Form.Group controlId="image1">
                                <Form.Label>Image1</Form.Label>
                                <Form.Control type="file" name="image1" onChange={handleChange} />
                            </Form.Group>

                            <Form.Group controlId="image2">
                                <Form.Label>Image2</Form.Label>
                                <Form.Control type="file" name="image2" onChange={handleChange} />
                            </Form.Group>

                            <Button variant="primary btn-sm" type="submit" className="mt-3">
                                {formData.id ? 'Update Schedule' : 'Add Schedule'}
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>

                {/* Entertainment Entries Table */}
                <Table striped bordered hover className="mt-5">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Schedule</th>
                            <th>Date</th>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Images</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentEntries.map((entry, index) => (
                            <tr key={entry.id}>
                                <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                <td>{entry.schedule}</td>
                                <td>{entry.date}</td>
                                <td>{entry.title}</td>
                                <td>{entry.description}</td>
                                <td>
                                    <img src={entry.image1} alt="Image 1" style={{ width: '50px', height: '50px' }} />
                                    <img src={entry.image2} alt="Image 2" style={{ width: '50px', height: '50px' }} />
                                </td>
                                <td>
                                    <Button variant="warning btn-sm me-3" onClick={() => handleOpenEdit(entry)}>Edit</Button>
                                    <Button variant="danger btn-sm" onClick={() => handleDelete(entry.id)}>Delete</Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>

                {/* Pagination Controls */}
                <Pagination className="justify-content-center mt-4">
                    <Pagination.Prev onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} disabled={currentPage === 1} />
                    {[...Array(totalPages).keys()].map(page => (
                        <Pagination.Item key={page + 1} active={page + 1 === currentPage} onClick={() => setCurrentPage(page + 1)}>
                            {page + 1}
                        </Pagination.Item>
                    ))}
                    <Pagination.Next onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages} />
                </Pagination>
            </div>
        </>
    );
};

export default Entertainment;
