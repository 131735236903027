import React, { useState, useEffect } from 'react';
import { IoArrowBackCircle } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import Header from '../Header/header';
import { MdDelete } from 'react-icons/md';

const Program = () => {
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [programs, setPrograms] = useState([]);
    const [formData, setFormData] = useState({ name: '', image1: null, image2: null, image3: null });
    const [editIndex, setEditIndex] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedImage, setSelectedImage] = useState(null); // For image modal
    const itemsPerPage = 10;

    useEffect(() => {
        fetchPrograms();
    }, []);

    const fetchPrograms = async () => {
        try {
            const response = await axios.get('https://isameapi.emedha.in/pages/api/api/programs');
            setPrograms(response.data);
        } catch (error) {
            console.error('Error fetching programs:', error);
        }
    };

    const handleBack = () => {
        navigate(-1);
    };

    const handleShow = (index = null) => {
        if (index !== null) {
            setFormData(programs[index]);
            setEditIndex(index);
        } else {
            setFormData({ name: '', image1: null, image2: null, image3: null });
            setEditIndex(null);
        }
        setShow(true);
    };

    const handleClose = () => setShow(false);

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (files) {
            setFormData({ ...formData, [name]: files[0] });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const dataToSubmit = new FormData();
        dataToSubmit.append('name', formData.name);

        if (formData.image1) dataToSubmit.append('image1', formData.image1);
        if (formData.image2) dataToSubmit.append('image2', formData.image2);
        if (formData.image3) dataToSubmit.append('image3', formData.image3);

        if (editIndex !== null) {
            try {
                await axios.put(`https://isameapi.emedha.in/pages/api/api/programs/${programs[editIndex].id}`, dataToSubmit, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
                const updatedPrograms = programs.map((program, index) =>
                    index === editIndex ? { ...program, ...formData } : program
                );
                setPrograms(updatedPrograms);
            } catch (error) {
                console.error('Error updating program:', error);
            }
        } else {
            try {
                const response = await axios.post('https://isameapi.emedha.in/pages/api/api/programs', dataToSubmit, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
                fetchPrograms();
                setPrograms([...programs, response.data]);
            } catch (error) {
                console.error('Error adding program:', error);
            }
        }
        setFormData({ name: '', image1: null, image2: null, image3: null });
        handleClose();
    };

    const handleDelete = async (index) => {
        const programToDelete = programs[index];
        try {
            await axios.delete(`https://isameapi.emedha.in/pages/api/api/programs/${programToDelete.id}`);
            const newPrograms = programs.filter((_, i) => i !== index);
            setPrograms(newPrograms);
        } catch (error) {
            console.error('Error deleting program:', error);
        }
    };

    const handleImageClick = (imageUrl) => {
        setSelectedImage(imageUrl);
    };

    const handleCloseImageModal = () => {
        setSelectedImage(null);
    };

    const totalPages = Math.ceil(programs.length / itemsPerPage);
    const currentPrograms = programs.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    return (
        <>
            <Header />
            <div className="container mt-5">
                <h1 className="d-flex justify-content-between align-items-center mb-4">
                    <IoArrowBackCircle onClick={handleBack} style={{ cursor: 'pointer' }} />
                    Special Events
                    <Button className="btn btn-primary btn-sm" onClick={() => handleShow()}>+ Add</Button>
                </h1>

                {/* Table to display programs */}
                <div className="table-responsive">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Program Name</th>
                                <th>Images</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentPrograms.map((program, index) => (
                                <tr key={program.id}>
                                    <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                    <td>{program.name}</td>
                                    <td>
                                        {/* Display the images if they exist */}
                                        <div>
                                            {program.image1 && (
                                                <img 
                                                    src={program.image1} 
                                                    alt="Image 1" 
                                                    style={{ width: '50px', height: 'auto', marginRight: '10px', cursor: 'pointer' }} 
                                                    onClick={() => handleImageClick(program.image1)}
                                                />
                                            )}
                                            {program.image2 && (
                                                <img 
                                                    src={program.image2} 
                                                    alt="Image 2" 
                                                    style={{ width: '50px', height: 'auto', marginRight: '10px', cursor: 'pointer' }} 
                                                    onClick={() => handleImageClick(program.image2)}
                                                />
                                            )}
                                            {program.image3 && (
                                                <img 
                                                    src={program.image3} 
                                                    alt="Image 3" 
                                                    style={{ width: '50px', height: 'auto', cursor: 'pointer' }} 
                                                    onClick={() => handleImageClick(program.image3)}
                                                />
                                            )}
                                        </div>
                                    </td>
                                    <td>
                                        <Button variant="warning btn-sm me-2" onClick={() => handleShow((currentPage - 1) * itemsPerPage + index)}>Edit</Button>
                                        <MdDelete style={{ cursor: 'pointer' }} onClick={() => handleDelete((currentPage - 1) * itemsPerPage + index)} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {/* Pagination Controls */}
                <nav>
                    <ul className="pagination justify-content-center">
                        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                            <button className="page-link" onClick={() => setCurrentPage(currentPage - 1)}>Previous</button>
                        </li>
                        {Array.from({ length: totalPages }, (_, i) => (
                            <li key={i + 1} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                                <button className="page-link" onClick={() => setCurrentPage(i + 1)}>{i + 1}</button>
                            </li>
                        ))}
                        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                            <button className="page-link" onClick={() => setCurrentPage(currentPage + 1)}>Next</button>
                        </li>
                    </ul>
                </nav>

                {/* Modal for adding/editing program */}
                <Modal show={show} onHide={handleClose} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>{editIndex !== null ? 'Edit Program' : 'Add Program'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="image1" className="form-label">Upload Image 1</label>
                                <input type="file" className="form-control" id="image1" name="image1" accept="image/*" onChange={handleChange} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="image2" className="form-label">Upload Image 2</label>
                                <input type="file" className="form-control" id="image2" name="image2" accept="image/*" onChange={handleChange} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="image3" className="form-label">Upload Image 3</label>
                                <input type="file" className="form-control" id="image3" name="image3" accept="image/*" onChange={handleChange} />
                            </div>
                            <Form.Group controlId="formName">
                                <Form.Label>Program Name</Form.Label>
                                <Form.Control type="text" name="name" value={formData.name} onChange={handleChange} required />
                            </Form.Group>                          
                            <Button variant="primary btn-sm mt-2" type="submit">
                                Submit
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>

                {/* Image Preview Modal */}
                <Modal show={selectedImage !== null} onHide={handleCloseImageModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Full Image</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <img src={selectedImage} alt="Full Image" style={{ width: '100%', height: 'auto' }} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseImageModal}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    );
};

export default Program;
