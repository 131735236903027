// import React, { useState, useEffect } from 'react';
// import { IoArrowBackCircle } from 'react-icons/io5';
// import Header from '../Header/header';
// import { useNavigate } from 'react-router-dom';
// import { Pagination } from 'react-bootstrap';

// const LbnSignup = () => {
//     const navigate = useNavigate();
//     const [data, setData] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//     const [currentPage, setCurrentPage] = useState(1);
//     const [itemsPerPage, setItemsPerPage] = useState(10);
//     const [totalPages, setTotalPages] = useState(1);

//     const handleBack = () => {
//         navigate(-1);
//     };

//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const response = await fetch('https://isameapi.emedha.in/pages/api/lbnsignup');
//                 if (!response.ok) {
//                     throw new Error('Failed to fetch data');
//                 }
//                 const result = await response.json();
//                 setData(result);
//                 setTotalPages(Math.ceil(result.length / itemsPerPage));
//                 setLoading(false);
//             } catch (error) {
//                 setError(error.message);
//                 setLoading(false);
//             }
//         };

//         fetchData();
//     }, []);

//     const handleDelete = async (id) => {
//         const confirmDelete = window.confirm('Are you sure you want to delete this entry?');
//         if (!confirmDelete) return;

//         try {
//             const response = await fetch(`https://isameapi.emedha.in/pages/api/lbnsignup/${id}`, {
//                 method: 'DELETE',
//             });

//             if (response.ok) {
//                 setData(data.filter(item => item.id !== id));
//             } else {
//                 throw new Error('Failed to delete the item');
//             }
//         } catch (error) {
//             setError(error.message);
//         }
//     };

//     const indexOfLastItem = currentPage * itemsPerPage;
//     const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//     const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

//     return (
//         <>
//             <Header />
//             <div className="container mt-5">
//                 <h1 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
//                     <IoArrowBackCircle onClick={handleBack} style={{ cursor: 'pointer' }} />
//                     LBN Signup
//                 </h1>

//                 {loading && <div>Loading...</div>}
//                 {error && <div>Error: {error}</div>}
//                 {!loading && !error && (
//                     <>
//                         <div className="table-responsive">
//                             <table className="table table-bordered table-striped">
//                                 <thead>
//                                     <tr>
//                                         <th>#</th>
//                                         <th>Registration No</th>
//                                         <th>Name</th>
//                                         <th>Mobile</th>
//                                         <th>Email</th>
//                                         <th>Password</th>
//                                         <th>District</th>
//                                         <th>Company Name</th>
//                                         <th>City</th>
//                                         <th>Entity Type</th>
//                                         <th>Products Services</th>
//                                         <th>Interested In lbn</th>
//                                         <th>Investment</th>
//                                         <th>Global Networking</th>
//                                         <th>Date</th>
//                                         <th>Action</th>
//                                     </tr>
//                                 </thead>
//                                 <tbody>
//                                     {currentItems.length > 0 ? (
//                                         currentItems.map((item, index) => (
//                                             <tr key={item.id}>
//                                                 <td>{indexOfFirstItem + index + 1}</td>
//                                                 <td>{item.registration_no}</td>
//                                                 <td>{item.name}</td>
//                                                 <td>{item.mobile}</td>
//                                                 <td>{item.email}</td>
//                                                 <td>{item.password}</td>
//                                                 <td>{item.district}</td>
//                                                 <td>{item.company_name}</td>
//                                                 <td>{item.city}</td>
//                                                 <td>{item.entity_type}</td>
//                                                 <td>{item.products_services}</td>
//                                                 <td>{item.interested_in_lbn}</td>
//                                                 <td>{item.investment}</td>
//                                                 <td>{item.global_networking}</td>
//                                                 <td>{new Date(item.created_at).toLocaleDateString()}</td>
//                                                 <td>
//                                                     <button onClick={() => handleDelete(item.id)} className="btn btn-danger btn-sm">
//                                                         Delete
//                                                     </button>
//                                                 </td>
//                                             </tr>
//                                         ))
//                                     ) : (
//                                         <tr>
//                                             <td colSpan="7">No data available</td>
//                                         </tr>
//                                     )}
//                                 </tbody>
//                             </table>
//                         </div>

//                         <Pagination className="justify-content-center mt-4">
//                             <Pagination.Prev onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} disabled={currentPage === 1} />
//                             {[...Array(totalPages)].map((_, pageIndex) => (
//                                 <Pagination.Item key={pageIndex + 1} active={pageIndex + 1 === currentPage} onClick={() => setCurrentPage(pageIndex + 1)} >
//                                     {pageIndex + 1}
//                                 </Pagination.Item>
//                             ))}
//                             <Pagination.Next onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages} />
//                         </Pagination>
//                     </>
//                 )}
//             </div>
//         </>
//     );
// };

// export default LbnSignup;


import React, { useState, useEffect } from 'react';
import { IoArrowBackCircle } from 'react-icons/io5';
import Header from '../Header/header';
import { useNavigate } from 'react-router-dom';
import { Pagination } from 'react-bootstrap';

const LbnSignup = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    const handleBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://isameapi.emedha.in/pages/api/lbnsignup');
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                const result = await response.json();
                setData(result);
                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleDelete = async (id) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this entry?');
        if (!confirmDelete) return;

        try {
            const response = await fetch(`https://isameapi.emedha.in/pages/api/lbnsignup/${id}`, {
                method: 'DELETE',
            });

            if (response.ok) {
                setData(data.filter(item => item.id !== id));
            } else {
                throw new Error('Failed to delete the item');
            }
        } catch (error) {
            setError(error.message);
        }
    };

    const [searchQuery, setSearchQuery] = useState('');

    // Filter data based on the search query
    const filteredAttendees = data.filter(attendee => {
        return (
            attendee.registration_no?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            attendee.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            attendee.company_name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            attendee.city?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            attendee.email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            attendee.district?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            attendee.products_services?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            attendee.interested_in_lbn?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            attendee.investment?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            attendee.global_networking?.toString().includes(searchQuery)
        );
    });

    // Calculate totalPages based on filtered attendees
    const totalPages = Math.ceil(filteredAttendees.length / itemsPerPage);

    // Paginate the filtered data
    const currentAttendees = filteredAttendees.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    return (
        <>
            <Header />
            <div className="container mt-5">
                <h1 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                    <IoArrowBackCircle onClick={handleBack} style={{ cursor: 'pointer' }} />
                    LBN Signup
                    <div>
                        <input
                            className="form-control mr-sm-2"
                            type="search"
                            placeholder="Search"
                            aria-label="Search"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div>
                </h1>

                {loading && <div>Loading...</div>}
                {error && <div>Error: {error}</div>}
                {!loading && !error && (
                    <>
                        <div className="table-responsive">
                            <table className="table table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Registration No</th>
                                        <th>Name</th>
                                        <th>Mobile</th>
                                        <th>Email</th>
                                        <th>Password</th>
                                        <th>District</th>
                                        <th>Company Name</th>
                                        <th>City</th>
                                        <th>Entity Type</th>
                                        <th>Products Services</th>
                                        <th>Interested In lbn</th>
                                        <th>Investment</th>
                                        <th>Global Networking</th>
                                        <th>Date</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentAttendees.length > 0 ? (
                                        currentAttendees.map((item, index) => (
                                            <tr key={item.id}>
                                                <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                                <td>{item.registration_no}</td>
                                                <td>{item.name}</td>
                                                <td>{item.mobile}</td>
                                                <td>{item.email}</td>
                                                <td>{item.password}</td>
                                                <td>{item.district}</td>
                                                <td>{item.company_name}</td>
                                                <td>{item.city}</td>
                                                <td>{item.entity_type}</td>
                                                <td>{item.products_services}</td>
                                                <td>{item.interested_in_lbn}</td>
                                                <td>{item.investment}</td>
                                                <td>{item.global_networking}</td>
                                                <td>{new Date(item.created_at).toLocaleDateString()}</td>
                                                <td>
                                                    <button onClick={() => handleDelete(item.id)} className="btn btn-danger btn-sm">
                                                        Delete
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="7">No data available</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>

                        <Pagination className="justify-content-center mt-4">
                            <Pagination.Prev onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} disabled={currentPage === 1} />
                            {[...Array(totalPages)].map((_, pageIndex) => (
                                <Pagination.Item key={pageIndex + 1} active={pageIndex + 1 === currentPage} onClick={() => setCurrentPage(pageIndex + 1)} >
                                    {pageIndex + 1}
                                </Pagination.Item>
                            ))}
                            <Pagination.Next onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages} />
                        </Pagination>
                    </>
                )}
            </div>
        </>
    );
};

export default LbnSignup;
