import React, { useState, useEffect } from 'react';
import { IoArrowBackCircle } from 'react-icons/io5';
import Header from '../Header/header';
import { useNavigate } from 'react-router-dom';
import { Pagination } from 'react-bootstrap';

const Gallery = () => {
    const navigate = useNavigate();

    const [photos, setPhotos] = useState([]);
    const [captures, setCaptures] = useState([]);
    const [currentPhotoPage, setCurrentPhotoPage] = useState(1);
    const [currentCapturePage, setCurrentCapturePage] = useState(1);
    const itemsPerPage = 10; // Items per page

    useEffect(() => {
        // Fetch photos data
        const fetchPhotos = async () => {
            try {
                const response = await fetch('https://isameapi.emedha.in/pages/api/photos');
                const data = await response.json();
                setPhotos(data);
            } catch (err) {
                console.error('Error fetching photos:', err);
            }
        };

        // Fetch capture data
        const fetchCaptures = async () => {
            try {
                const response = await fetch('https://isameapi.emedha.in/pages/api/capture');
                const data = await response.json();
                setCaptures(data);
            } catch (err) {
                console.error('Error fetching captures:', err);
            }
        };

        fetchPhotos();
        fetchCaptures();
    }, []);

    const totalPhotoPages = Math.ceil(photos.length / itemsPerPage);
    const totalCapturePages = Math.ceil(captures.length / itemsPerPage);
    const currentPhotos = photos.slice((currentPhotoPage - 1) * itemsPerPage, currentPhotoPage * itemsPerPage);
    const currentCaptures = captures.slice((currentCapturePage - 1) * itemsPerPage, currentCapturePage * itemsPerPage);

    const handlePhotoPageChange = (page) => setCurrentPhotoPage(page);
    const handleCapturePageChange = (page) => setCurrentCapturePage(page);

    const handleBack = () => {
        navigate(-1);
    };

    // Function to handle status change for photo
    const handlePhotoStatusChange = async (id, newStatus) => {
        try {
            const response = await fetch('https://isameapi.emedha.in/pages/api/update-photo-status', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id,
                    status: newStatus,
                }),
            });
            const data = await response.json();
            if (response.ok) {
                // Update state with new status
                setPhotos((prevPhotos) =>
                    prevPhotos.map((photo) =>
                        photo.id === id ? { ...photo, status: newStatus } : photo
                    )
                );
            } else {
                console.error('Error updating photo status:', data.error);
            }
        } catch (err) {
            console.error('Error during status update:', err);
        }
    };

    // Function to handle status change for capture
    const handleCaptureStatusChange = async (id, newStatus) => {
        try {
            const response = await fetch('https://isameapi.emedha.in/pages/api/update-capture-status', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id,
                    status: newStatus,
                }),
            });
            const data = await response.json();
            if (response.ok) {
                // Update state with new status
                setCaptures((prevCaptures) =>
                    prevCaptures.map((capture) =>
                        capture.id === id ? { ...capture, status: newStatus } : capture
                    )
                );
            } else {
                console.error('Error updating capture status:', data.error);
            }
        } catch (err) {
            console.error('Error during status update:', err);
        }
    };

    // Function to delete a photo
    const handleDeletePhoto = async (id) => {
        try {
            const response = await fetch(`https://isameapi.emedha.in/pages/api/photo/${id}`, {
                method: 'DELETE',
            });
            if (response.ok) {
                // Remove the photo from the state after deletion
                setPhotos((prevPhotos) => prevPhotos.filter((photo) => photo.id !== id));
            } else {
                console.error('Error deleting photo');
            }
        } catch (err) {
            console.error('Error during photo deletion:', err);
        }
    };

    // Function to delete a capture
    const handleDeleteCapture = async (id) => {
        try {
            const response = await fetch(`https://isameapi.emedha.in/pages/api/capture/${id}`, {
                method: 'DELETE',
            });
            if (response.ok) {
                // Remove the capture from the state after deletion
                setCaptures((prevCaptures) => prevCaptures.filter((capture) => capture.id !== id));
            } else {
                console.error('Error deleting capture');
            }
        } catch (err) {
            console.error('Error during capture deletion:', err);
        }
    };

    return (
        <>
            <Header />
            <div className="container mt-5">
                <h1 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                    <IoArrowBackCircle onClick={handleBack} style={{ cursor: 'pointer' }} />
                    Gallery
                </h1>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {/* Photos Table - Left side */}
                    <div style={{ flex: 1, marginRight: '10px' }}>
                        <h2>Photo with Tony Benbow</h2>
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Photo</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentPhotos.length === 0 ? (
                                    <tr><td colSpan="4">Loading...</td></tr>
                                ) : (
                                    currentPhotos.map((photo) => (
                                        <tr key={photo.id}>
                                            <td>{photo.capture ? (<img src={photo.capture} alt={`Capture ${photo.id}`} width="50" style={{ borderRadius: '5px' }} />) : ("No Image")}</td>
                                            <td>{photo.status}</td>
                                            <td>
                                                <button onClick={() => handlePhotoStatusChange(photo.id, photo.status === 'approved' ? 'Not Approved' : 'approved')} className="btn btn-primary btn-sm me-2">
                                                    Approve
                                                </button>
                                                <button onClick={() => handleDeletePhoto(photo.id)} className="btn btn-danger btn-sm">
                                                    Delete
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>

                        {/* Pagination for photos */}
                        <Pagination className="justify-content-center mt-4">
                            <Pagination.Prev onClick={() => setCurrentPhotoPage(prev => Math.max(prev - 1, 1))} disabled={currentPhotoPage === 1} />
                            {[...Array(totalPhotoPages).keys()].map(page => (
                                <Pagination.Item key={page + 1} active={page + 1 === currentPhotoPage} onClick={() => handlePhotoPageChange(page + 1)}>
                                    {page + 1}
                                </Pagination.Item>
                            ))}
                            <Pagination.Next onClick={() => setCurrentPhotoPage(prev => Math.min(prev + 1, totalPhotoPages))} disabled={currentPhotoPage === totalPhotoPages} />
                        </Pagination>
                    </div>

                    {/* Captures Table - Right side */}
                    <div style={{ flex: 1, marginLeft: '10px' }}>
                        <h2>Capture a moment</h2>
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Capture a moment</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentCaptures.length === 0 ? (
                                    <tr><td colSpan="4">Loading...</td></tr>
                                ) : (
                                    currentCaptures.map((capture) => (
                                        <tr key={capture.id}>
                                            <td>{capture.capture ? (<img src={capture.capture} alt={`Capture ${capture.id}`} width="50" style={{ borderRadius: '5px' }} />) : ("No Image")}</td>
                                            <td>{capture.status}</td>
                                            <td>
                                                <button onClick={() => handleCaptureStatusChange(capture.id, capture.status === 'approved' ? 'Not Approved' : 'approved')} className="btn btn-primary btn-sm me-2" >
                                                    Approve
                                                </button>
                                                <button onClick={() => handleDeleteCapture(capture.id)} className="btn btn-danger btn-sm" >
                                                    Delete
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>

                        {/* Pagination for captures */}
                        <Pagination className="justify-content-center mt-4">
                            <Pagination.Prev onClick={() => setCurrentCapturePage(prev => Math.max(prev - 1, 1))} disabled={currentCapturePage === 1} />
                            {[...Array(totalCapturePages).keys()].map(page => (
                                <Pagination.Item key={page + 1} active={page + 1 === currentCapturePage} onClick={() => handleCapturePageChange(page + 1)}>
                                    {page + 1}
                                </Pagination.Item>
                            ))}
                            <Pagination.Next onClick={() => setCurrentCapturePage(prev => Math.min(prev + 1, totalCapturePages))} disabled={currentCapturePage === totalCapturePages} />
                        </Pagination>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Gallery;
